import {useEffect, useState} from "react";
import {ACTIONS} from "../constants";
import {Alert, App, Button, Input, Space, Table} from "antd";
import {ClipButton, groupData, requestFn} from "../helpers";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import Fuse from "fuse.js";


export const Events = ({token}) => {
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)

    const {notification, modal} = App.useApp();

    const modalConfig = (updateId, children, id) => ({
        title: children?.length ? `Revert ${children.length} events?` : `Revert event ${id}`,
        content: 'Do you really want to revert these events?',
        onOk: async () => {
            const childrenIds = children ? children.map((item) => item.suid) : [id]
            await requestFn(
                'PATCH',
                '/devices',
                token,
                () => {
                    notification.success({message: 'Revert succesful'})
                    return getData()
                },
                setError,
                {
                    childrenIds,
                    updateId,
                }
            )
        }
    })

    const getData = async () => {
        await requestFn('GET', '/events', token, setData, setError)
        setLoading(false)
    }


    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Time',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (stamp) => dayjs(stamp).format('DD.MM.YY hh:mm'),
            sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
            sortOrder: 'ascend'
        },
        {
            title: 'SUID',
            dataIndex: 'updateData',
            key: 'suid',
            render: (data) => (
              <div style={{display: 'flex', justifyContent: 'space-between' }}>
                <Link to={`/device/${data?.suid}`}>{data?.suid}</Link>
                <ClipButton text={data?.suid} />
              </div>
            )
        },
        {
            title: 'MAC',
            dataIndex: 'updateData',
            key: 'mac',
            render: (data) => (
              <div style={{display: 'flex', justifyContent: 'space-between' }}>
                  <div>{data?.mac}</div>
                  <ClipButton text={data?.mac} />
              </div>
            )
        },
        {
            title: 'Update ID',
            dataIndex: 'updateId',
            key: 'updateId',
        },
        {
            title: 'Count',
            dataIndex: 'children',
            key: 'count',
            render: (data) => data?.length
        },
        {
            title: 'Created by',
            dataIndex: 'ownerAlias',
            key: 'ownerAlias',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (row) => {
                return (
                    row.type !== ACTIONS.REVERT &&
                    row.type !== ACTIONS.CREATE &&
                    !data.some((item) => item.revertedId === row.updateId)
                ) && (
                    <Button
                        onClick={() => modal.confirm(
                            modalConfig(row.updateId, row.children, row.updateData?.suid)
                        )}
                    >
                        {row.children?.length ? 'Revert all' : 'Revert'}
                    </Button>
                );
            }
        }
    ];

    const fuse = new Fuse(data, {
        keys: ['suid', 'mac'],
        threshold: 0.8,
    })
    const fuseData = fuse.search(search).map(({item}) => item)

    const groupedData = groupData(fuseData.length ? fuseData : data, 'updateId')

    const tableData = Object.values(groupedData).map((item) => {
        if (item.length === 1) {
            return ({...item[0], key: item[0].updateId})
        } else {
            return ({
                key: item[0].updateId + item[0].timestamp,
                type: item[0].type,
                timestamp: item[0].timestamp,
                children: item.map((childrenItem) => ({
                    ...childrenItem,
                    key: childrenItem.suid
                })),
                updateId: item[0].updateId,
                ownerAlias: item[0].ownerAlias,
            })
        }
    })

    useEffect(() => {
        getData()
    }, []);
    return (
        <Space direction='vertical' style={{padding: 30, width: '100%'}}>
            {error && <Alert type='error' message={error}/>}
            Search
            <Input
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                style={{width: 300}}
            />
            <Table
                columns={columns}
                dataSource={tableData}
                pagination
                loading={loading}
                rowKey='key'
            />
        </Space>
    )
}
